.eventshow-container {
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.eventshow-title {
  font-family: "Poppins";
  color: whitesmoke;
  font-size: 2em;
  font-weight: 300;
}

.eventshow-title-container {
  width: 100%;
  background-color: #1d3c45;
  text-align: center;
}

.img-map-container {
  width: 100%;
  height: 50vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.eventshow-image-container {
  width: 60%;
}

.test-img-show {
  width: 100%;
  height: 100%;
}

.eventshow-map-container {
  width: 40%;
  height: 100%;
}

.eventshow-info-container {
  font-family: "Poppins";
  display: flex;
  justify-content: center;
  width: 100%;
  padding-bottom: 10px;
}

.eventshow-info-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 60%;
  text-align: justify;
}

.eventshow-info-right {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 40%;
}

.eventshow-info-title {
  color: #939696;
}

.eventshow-info {
  color: #1d3c45;
}

.eventshow-date-container {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 10px;
  box-sizing: border-box;
}

.calendar-container {
  display: flex;
  flex-direction: row;
  padding: 10px;
}

.time-container {
  display: flex;
  flex-direction: row;
  padding: 10px;
}

.eventshow-btn-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
  box-sizing: border-box;
}

.eventshow-btn {
  font-family: "Poppins";
  color: #1d3c45;
  background-color: whitesmoke;
  padding: 10px;
  font-size: 1.3em;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 2px;
}

.eventshow-btn:hover {
  color: whitesmoke;
  background-color: #d2601a;
}

#back-eventshow-btn {
  background-color: whitesmoke;
  padding: 10px;
  font-size: 1.6em;
  margin-right: 0px !important;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
  border-radius: 2px;
}

#back-eventshow-btn:hover {
  background-color: #939696;
}

@media only screen and (max-width: 450px) {

  .test-img-show {
    display: none;
  }
  .map {
    width: 100vw;
  }
}

@media only screen and (max-width: 700px) {
  .img-map-container {

    height: 50vh;
  }
  .eventshow-image-container {
    width: 100%;
  }
  .eventshow-map-container {
    width: 100%;
  }
  .eventshow-info-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .eventshow-info-left {
    width: 90%;
  }
  .test-img-show {
    width: 100%;
    height: 100%;
  }
}

@media only screen and (min-width: 701px) {
  .eventshow-image-container {
    width: 100%;
  }
  .eventshow-map-container {
    width: 100%;
  }
  .eventshow-info-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .eventshow-info-left {
    width: 90%;
  }
}
