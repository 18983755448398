.contact {
  font-family: "Poppins";
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.contact-top {
  width: 100vw;
  height: 20vh; /* <--- mettre env. 30vh en max-height: env. 500px */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #1d3c45;
  margin-bottom: 30px;
}

.contact-title-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}

.contact-section1 {
  display: flex;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 50px;
}

.contact-section1-left {
  width: 70%;
  height: 100%;
  flex-direction: column;
}

.contact-section1-left-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 70px;
}

.section1-logo {
  width: 35px;
  height: 35px;
}

.contact-left-text {
  color: #1d3c45;
}

.contact-section1-right {
  margin-right: 20px;
  width: 100%;
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.map-container {
  width: 80%;
  height: 50vh;
  border: solid 1px #1d3c45;
  box-shadow: rgba(0, 0, 0, 0.5) 2.4px 2.4px 3.2px;
}

.contact-section2 {
  width: 100%;
}

.contact-title {
  font-family: "Caveat";
  color: #f5f5f5;
  font-size: 4rem;
}

.contact-logo {
  width: 80px;
  height: 80px;
  padding-left: 1.6em;
}

#title1 {
  margin-top: 50px;
}

#title2 {
  margin-top: 20px;
  color: #1d3c45;
}

#title4 {
  margin-top: 20px;
  color: #1d3c45;
}

.join-us-btn {
  color: #1d3c45;
  border: solid 1px #1d3c45;
  border-radius: 5px;
  padding: 5px;
  cursor: pointer;
  font-size: 1.5rem;
  padding: 10px;
}

.join-us-btn:hover {
  background-color: #1d3c45;
  color: white;
}

.contact-form {
  margin-top: 60px;
  margin-bottom: 20px;
}

.feedback-input {
  color: black;
  font-family: "Poppins";
  font-weight: 500;
  font-size: 18px;
  border-radius: 5px;
  line-height: 22px;
  background-color: transparent;
  border: 1px solid #1d3c45;
  transition: all 0.3s;
  padding: 16px;
  margin-bottom: 15px;
  width: 80%;
  box-sizing: border-box;
  outline: 0;
}

.feedback-input:focus {
  border: 2px solid #d2601a;
}
.feedback-input::placeholder {
  font-weight: 400;
  color: #747575;
}
textarea {
  height: 150px;
  line-height: 150%;
  resize: vertical;
}

[type="submit"] {
  font-family: "Poppins";
  width: 50%;
  background: #1d3c45;
  border-radius: 5px;
  border: 0;
  cursor: pointer;
  color: white;
  font-size: 24px;
  padding-top: 10px;
  padding-bottom: 10px;
  transition: all 0.3s;
  margin-top: -4px;
}
[type="submit"]:hover {
  background: #d2601a;
}

@media screen and (max-width: 800px) {
  .contact-section1 {
    flex-direction: column;
  }
  .contact-section1-left {
    width: 100%;
  }
  .contact-section1-left-container {
    margin-top: 0;
  }
  .contact-section1-right {
    min-width: 100%;
    margin-right: 0;
    padding: 20px;
    box-sizing: border-box;
  }
}

/*

  @media screen and (max-height: 450px) {
    .contact-top {
      height:45vh;
    }
  }

  @media screen and (min-height:451px) and (max-height: 700px) {
    .contact-top {
      height:25vh;
    }
  }
  */
