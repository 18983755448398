.notices-container {
  width: 100vw;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: "Poppins";
}

.notices-title {
  color: #1d3c45;
}

.notices-text {
  width: 80vw;
}

.notices-cookies-list {
  width: 80vw;
  text-align: justify;
}
