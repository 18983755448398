.profil-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.profil-card {
  font-family: "Poppins";
  margin-top: 80px;
  width: 50vw;
  height: 65vh;
  border: solid 1px #1d3c45;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  padding-bottom: 10px;
}

.avatar-container {
  width: 100%;
  height: 35%;
  border: solid 1px #1d3c45;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  background-color: #1d3c45;
}

#avatar-profil {
  margin-top: 10px;
  margin-bottom: 10px;
}

.second-container {
  display: flex;
  flex-direction: column;

  align-items: center;
  width: 100%;
  height: 100%;
}

.name-container {
  width: 100%;
  height: 30%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
}

.contact-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.info-container {
  text-align: center;
}

#num {
  margin-top: 15px;
}

#city {
  margin-top: 15px;
}

.profil-infos {
  color: #1d3c45;
}

@media only screen and (max-width: 300px) {
  .profil-card {
    width: 100%;
    height: 100%;
  }
}

@media only screen and (min-width: 301px) and (max-width: 600px) {
  .profil-card {
    width: 90%;
    height: 100%;
  }
}

@media only screen and (min-width: 601px) and (max-width: 900px) {
  .profil-card {
    width: 75%;
    height: 100%;
  }
}

@media only screen and (min-width: 901px) {
  .profil-card {
    width: 60%;
    height: 100%;
  }
}

.profil-event-card {
  width: 100%;
  margin-top: -80px;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.profil-events-title {
  font-family: "Poppins";
  color: #3c3b3ca6;
}

.profil-event {
  font-family: "Poppins";
  width: 80%;
  height: 20vh;
  border-top: solid 1px whitesmoke;
  background-color: #1d3c45;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.profil-event-img {
  width: 30%;
  height: 100%;
}

.profil-event-title-container {
  width: 60%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.profil-event-title {
  color: whitesmoke;
  font-size: 1.3em;
  margin-left: 5px;
}

.profil-event-date-container {
  width: 20%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: -40px;
}

.profil-event-date {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
}

.profil-btn-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 50vw;
  padding: 10px;
  margin-top: 10px;
  font-family: "Poppins";
}

.edit-profil-btn {
  color: #1d3c45;
  border: solid 1px #1d3c45;
  border-radius: 5px;
  padding: 5px;
  cursor: pointer;
  font-size: 1.2rem;
  padding: 10px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  transition: all 0.3s ease-in-out;
  margin-bottom: 5em;
}

.edit-profil-btn:hover {
  background-color: #d5d8d9;
  transition: all 0.3s ease-in-out;
}

.delete-account-btn {
  font-family: "Poppins";
  width: 50%;
  padding: 20px;
  color: red;
  border-color: red;
  border-width: 0.5px;
  font-size: 1em;
  cursor: pointer;
}

.delete-account-btn:hover {
  background-color: red;
  color: whitesmoke;
}

@media only screen and (min-width: 1550px) {
  .profil-event {
    width: 50%;
  }
}

@media only screen and (max-width: 550px) {
  .profil-event-text {
    font-size: small;
  }

  .profil-event-img {
    width: 0%;
    display: none;
  }
  .profil-event-date-container {
    width: 50%;
  }
}

@media only screen and (max-height: 500px) {
  .profil-event {
    height: 30vh;
  }
}
