.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -10px; /* gutter size offset */
  width: auto;
  margin-top: 60px;
}
.my-masonry-grid_column {
  padding-left: 10px; /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div {
  /* change div to reference your elements you put in <Masonry> */

  margin-bottom: 30px;
}

.pictures-container {
  text-align: center;
}

.pictures-dl-title {
  font-family: "Poppins";
}

.pictures-grid {
  max-width: 100vw;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-gap: 16px;
  margin-top: 30px;
}

.picture-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.picture {
  width: 100%;
  height: 100%;
}

.picture-title-container {
  width: 100%;
  background-color: #000000cb;
  height: 3vh;
}

.picture-title {
  font-family: "Poppins";
  margin-top: 0;
  color: white;
}

@media only screen and (max-width: 1200px) {
  .pictures-grid {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(3, 1fr);
  }
}

@media only screen and (max-width: 900px) {
  .pictures-grid {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(4, 1fr);
  }
}

@media only screen and (max-width: 700px) {
  .pictures-grid {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(5, 1fr);
  }
}

@media only screen and (max-width: 440px) {
  .pictures-grid {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(10, 1fr);
  }
}

.picture-popup {
  width: 99vw;
  position: fixed;
  top: 0;
  z-index: 203;
}

.picture-zoom {
  width: 100%;
}

.picture-popup-top {
  background-color: #000000cb;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 5vh;
}

.popup-top-title {
  font-family: "Poppins";
  color: white;
}

.popup-top-close {
  position: absolute;
  right: 0;
  height: 100%;
  cursor: pointer;
}

.popup-top-delete {
  position: absolute;
  left: 0;
  height: 100%;
  cursor: pointer;
}

@media only screen and (max-height: 450px) {
  .picture-popup {
    width: 80vw;
    left: 10%;
  }
}

@media only screen and (max-width: 500px) {
  .picture-popup {
    top: 25vh;
  }
}
