.footer-container {
  width: 100%;
  height: 100%;
  border-top: solid 2px #1d3c45;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #e3e3e6;
  margin-top: 30px;
}

.assoc-logo-footer {
  width: 50%;
  margin-top: 30px;
}

.logo-footer-container {
  height: 100%;
  width: 65%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.links-footer-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.footer-titles {
  font-family: "Poppins";
  color: #1d3c45;
  margin-bottom: 5px;
}

.footer-link {
  font-family: "Poppins";
  font-size: 0.9em;
  color: black;
}

.footer-link:hover {
  color: #d2601a;
}

.section1-footer {
  display: flex;
  flex-direction: column;
  padding: 10px;
  margin-bottom: 70px;
}

.section2 {
  margin-top: -55px;
}

.section2-a {
  display: flex;
  flex-direction: column;
}

.section2-b {
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 600px) {
  .footer-container {
    flex-direction: column;
    height: 100%;
    text-align: center;
  }
  .links-footer-container {
    flex-direction: column;
  }
  .logo-footer-container {
    width: 100%;
  }

}

@media only screen and (min-width: 601px) and (max-width: 1500px) {
  .logo-footer-container {
    width: 65%;
  }
  .section2 {
    display: flex;
    flex-direction: row;
    margin-top: -55px;
  }
  .section2-a {
    margin-right: 10px;
  }
  .section1-footer {
    margin-bottom: 0;
  }
  .links-footer-container {
    justify-content: space-around;
  }
}

@media only screen and (min-width: 700px) and (max-width: 800px) {
  .section2-a {
    margin-right: 40px;
  }
  .section2 {
    margin-top: -55px;
  }
}

@media only screen and (min-width: 801px) and (max-width: 1000px) {
  .section2-a {
    margin-right: 60px;
  }
  .section2 {
    margin-top: -55px;
  }
}

@media only screen and (min-width: 1300px) {
  .logo-footer-container {
    width: 35%;
  }
}

.footer-end {
  font-family: "Poppins";
  font-size: 1.1rem;
  width: 100%;
  height: 100%;
  background-color: #d8dcde;
  text-align: center;
  padding: 5px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.notices-btn {
  text-decoration: none;
  font-size: small;
  margin-top: -20px;
  color: #1d3c45;
}

.notices-btn:hover {
  color: #d2601a;
}
