.new-pwd-container {
    width:100%;
    display:flex;
    justify-content:center;
    align-items:center;
    flex-direction:column;
}

.new-pwd-top {
    width:80%;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    margin-top:40px;
}

.new-pwd-icon {
    width:100px;
    height:100px;
}

.new-pwd-title {
    font-family:"Poppins";
    color: #1d3c45;
}

#new-pwd-form {
    margin-top: -40px;
    background:none;
}

.checkbox-container {
    margin-top: -10px;
    width:80%;
    display: flex;
    justify-content: space-evenly;
    align-items:center;
}

#input-pwd {
    border-color: #1d3c45;
    color:black;
    
}

#input-pwd-confirm {
    border-color: #1d3c45;
    color:black;
    
}