#email-input {
  width: 100%;
}

#forgot-pwd-btn {
  width: 80%;
  border: solid 1px #d2601a;
  border-radius: 20px;
  margin-top: 20px;
}

.forgot-pwd-icon {
  margin-bottom: -10px;
}

.back-btn-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.back-btn {
  padding: 10px;
  font-family: "Poppins";
  color: #1d3c45;
  border: solid 1px #1d3c45;
  border-radius: 5px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  transition: all 0.3s ease-in-out;
}

.back-btn:hover {
  background-color: #d5d8d9;
  transition: all 0.3s ease-in-out;
}

.back-icon {
  width: 30px;
  height: 30px;
}
