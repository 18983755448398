.navbar-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #e7ebed;
}

.navbar {
  min-width: 100vw;
  height: 23vh;
  padding: 5px;
  background-color: #e3e3e6;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: space-evenly;
  z-index: 202;
  box-sizing: border-box;
}

.navbar-logo-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.assoc-logo {
  width: 80%;
  height: 80%;
  margin-top: 10px;
  margin-left: -100px;
}

.nav-links {
  margin-top: 30px;
  font-family: "Poppins";
  font-size: 0.95rem;
  font-weight: 400;
  color: #1d3c45;
  background-image: linear-gradient(
    to right,
    #d2601a,
    #d2601a 50%,
    #1d3c45 50%
  );
  background-size: 200% 100%;
  background-position: -100%;
  display: inline-block;
  padding: 5px 0;
  position: relative;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: all 0.3s ease-in-out;
}

.nav-links:before {
  content: "";
  background: #d2601a;
  display: block;
  position: absolute;
  bottom: -3px;
  left: 0;
  width: 0;
  height: 3px;
  transition: all 0.3s ease-in-out;
}

.nav-links:hover {
  background-position: 0;
}

.nav-links:hover::before {
  width: 100%;
}

.collapse {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.collapse-btn-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border: solid 1px #d5d8d9;
  transition: 0.5s ease-in-out;
}

.collapse-btn {
  font-family: "Poppins";
  width: 100%;
  cursor: pointer;
  padding: 10px;
  border: none;
  border-right: none;
  border-radius: 5px;
  background-color: whitesmoke;
  color: #1d3c45;
  margin-left: 3em;
}

.collapse-icon {
  width: 22px;
  height: 22px;
  margin-right: 30px;
  cursor: pointer;
  transition: 0.5s ease-in-out;
}

.collapse-icon-animation {
  transition: 0.5s ease-in-out;
  transform: rotate(180deg);
  width: 22px;
  height: 22px;
  margin-right: 30px;
  cursor: pointer;
}

.toggle {
  font-family: "Poppins";
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: solid 1px #d5d8d9;
  background-color: whitesmoke;
  opacity: 1;
  border-top: none;
  max-height: 0;
  transition: max-height 0.5s ease-in-out;
  -webkit-transition: max-height 0.5s ease-in-out;
  -moz-transition: max-height 0.5s ease-in-out;
  -ms-transition: max-height 0.5s ease-in-out;
  -o-transition: max-height 0.5s ease-in-out;
  overflow: hidden;
}

.toggle.open {
  max-height: 200px;
}

.collapse-link {
  color: #1d3c45;
  font-size: large;
  width: 100%;
  height: 100%;
  padding: 10px;
  text-align: center;
  margin-right: 0.6em;
  transition: background-color 0.5s;
  transition: color 0.5s;
}

.collapse-link:hover {
  transition: all 0.5s ease-in-out;
  background-color: #1d3c45;
  color: whitesmoke;
  opacity: 0.9;
}

@media only screen and (max-width: 299px) {
  .nav-links {
    display: none;
  }
  .navbar {
    justify-content: space-between;
  }

  .menu-links {
    font-size: 0.9rem;
  }
  .login-menu-container {
    flex-direction: column;
  }
}

@media only screen and (min-width: 300px) and (max-width: 900px) {
  .nav-links {
    display: none;
  }

  .navbar {
    justify-content: space-between;
  }
  .assoc-logo {
    width: 55% !important;
    height: 55% !important;
  }
}

@media only screen and (min-width: 599px) and (max-width: 900px) {
  .assoc-logo {
    width: 60% !important;
    height: 60% !important;
  }
  .navbar {
    justify-content: space-between;
  }
}
/* -------------------- BURGER ----------------------  */

.burger-logo {
  width: 50px;
  height: 50px;
  margin-right: 2em;
  margin-top: 1.5em;
  cursor: pointer;
  transition: all 0.8s linear;
}

/* ---------------------------- */
.menu {
  position: absolute;
  left: -100%;
  width: 100%;
  background-color: #1d3c45;
  border: solid 1px #1d3c45;
  border-right: none;
  border-left: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: "Poppins";
  z-index: 108;
  opacity: 0.982;
  transition: all 0.5s ease-in-out;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px,
    rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px,
    rgba(0, 0, 0, 0.07) 0px 16px 16px;
  overflow-y: scroll;
}

.menu.expanded {
  transition: all 0.5s ease-in-out;
  transform: translateX(+100%);
}

.login-menu-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px;
  box-sizing: border-box;
}

.login-btn {
  color: whitesmoke;
  border: solid 1px #d2601a;
  border-radius: 5px;
  padding: 5px;
  cursor: pointer;
  font-size: 1.2rem;
}

.login-btn:hover {
  background-color: #d2601a;
  color: white;
}

.menu-links-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.menu-links {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.5s ease-in-out;
  padding: 23px;
  font-size: 1.2rem;
  color: whitesmoke;
  transition: background-color 0.6s;
  transition: color 0.6s;
}

.menu-links:hover {
  transition: all 0.6s ease-in-out;
  color: #1d3c45;
  background-color: whitesmoke;
  opacity: 0.5;
}

@media only screen and (max-width: 579px) {
  .menu.expanded {
    left: -100%;
    transform: translateX(+100%);
  }
  .collapse {
    display: none;
  }
}

@media only screen and (min-width: 580px) and (max-width: 900px) {
  .collapse {
    display: none;
  }
  .menu.expanded {
    left: -100%;
    transform: translateX(+100%);
  }
}

@media only screen and (min-width: 901px) {
  .burger-logo {
    display: none;
  }
  .menu.expanded {
    display: none;
  }
  .assoc-logo {
    width: 55% !important;
    height: 55% !important;
  }
}

@media only screen and (min-width: 1200px) {
  .assoc-logo {
    width: 70% !important;
    height: 70% !important;
  }
}

@media only screen and (max-height: 600px) {
  .assoc-logo {
    width: 40% !important;
    height: 40% !important;
  }
  .navbar {
    height: 26vh;
  }
}
