.edit-profil-container {
  font-family: "Poppins";
  display: flex;
  width: 100vw;
  align-items: center;
  justify-content: space-evenly;
}

.edit-profil-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;
}

.profil-form {
  margin-top: 100px;
  width: 80%;
}

.edit-profil-confirm-btn {
  margin-top: 20px;
}

@media only screen and (max-width: 600px) {
  .edit-profil-container {
    flex-direction: column;
    justify-content: center;
  }
  .edit-profil-title {
    margin-top: 40px;
  }
  .profil-form {
    margin-top: 40px;
    width: 90%;
  }
}
