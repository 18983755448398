.dashboard-card {
  font-family: "Poppins";
  width: 50vw;
  display: flex;
  flex-direction: column;
  border: solid 1px #1d3c45;
  margin-top: 20px;
  border-radius: 5px;
}

.dashboard-title-container {
  width: 100%;
  height: 35%;
  background-color: #1d3c45;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dashboard-title {
  font-family: "Poppins";
  color: whitesmoke;
}

.dashboard-content {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.dashboard-case {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: solid 1px #3c3b3ca6;
}

.dashboard-case-title {
  margin-left: 3%;
}

.dashboard-case-result {
  margin-right: 3%;
}

.dashboard-events-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.dashboard-event {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 300px) {
  .dashboard-card {
    width: 100%;
  }
}

@media only screen and (min-width: 301px) and (max-width: 600px) {
  .dashboard-card {
    width: 90%;
  }
}

@media only screen and (min-width: 601px) and (max-width: 900px) {
  .dashboard-card {
    width: 75%;
  }
}

@media only screen and (min-width: 901px) {
  .dashboard-card {
    width: 60%;
  }
}
