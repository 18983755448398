.event-form {
  margin-top: 60px;
  margin-bottom: 20px;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.event-form-active {
  display: flex;
}

.add-logo {
  width: 50px;
  height: 50px;
}

.add-event-btn {
  font-family: "Poppins";
  width: 50%;
  border: solid 1px #2ecc71;
  background-color: whitesmoke;
  border-radius: 5px;
  cursor: pointer;
}

.add-event-btn:hover {
  background: #dbe0de;
  border: solid 1px #2ecc71;
}
