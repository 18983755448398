.book-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transition: 0.5s ease-in-out;
}

.book-card {
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.book-img-container {
  position: relative;
  width: 100vw;
}

.book-img {
  width: 100%;
  height: 25vh;
  object-fit: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0.75;
}

.book-top {
  width: 100%;
  height: 100%;
  top: 0;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.book-title {
  font-size: 4rem;
  font-weight: 500;
  font-family: "Caveat";
  color: whitesmoke;
}

.book-description {
  font-family: "Poppins";
  margin-top: 40px;
  text-align: justify;
  width: 100%;
}

.book-guidance {
  font-family: "Poppins";
  color: #1d3c45;
  margin-top: 30px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 10px;
  border: solid 1px #afb2b3;
  border-radius: 5px;
}

.first-message {
  font-family: "Poppins";
  color: #1d3c45;
  padding: 20px;
  margin-bottom: 30px;
}

.guidance-logo-container {
  height: 100%;
  margin-right: 10px;
}

.new-review-btn {
  margin-top: 40px;
  padding: 10px;
  font-family: "Poppins";
  width: 40vw;
  font-size: 1.2rem;
  color: #1d3c45;
  border: solid 1px #1d3c45;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.new-review-btn:hover {
  background-color: #1d3c45;
  color: white;
}

.review-card {
  font-family: "Poppins";
  margin-top: 60px;
  margin-bottom: 30px;
  width: 80%;
  max-height: 250px;
  border: solid 2px #1d3c45;
  border-radius: 5px;
  background-color: #f4f5f5;
  opacity: 0.9;
  color: black;
  display: flex;
  flex-direction: column;
  transition: 1s ease-in-out;
}

.review-card:hover {
  transition: 1.1s ease-in-out;
  transform: scale3d(1.07, 1.07, 1.07);
}

@media only screen and (min-width: 300px) and (max-width: 600px) {
  .review-card {
    width: 98%;
  }
}

.review-user-info {
  height: 40%;
  width: 100%;
  background-color: #e7ebed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
}

.review-default-avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 13%;
  height: 100%;
}

@media only screen and (min-width: 300px) and (max-width: 600px) {
  .review-card {
    width: 98%;
  }
}

@media only screen and (max-height: 599px) {
  .review-card {
    width: 98%;
  }
  .book-img {
    height: 60vh;
  }
}

@media only screen and (min-height: 600px) and (max-height: 1000px) {
  .review-card {
    width: 98%;
  }
  .book-img {
    height: 40vh;
  }
}
.review-name-container {
  display: flex;
  align-items: center;
}

.review-name {
  margin-right: 2px;
}

.review-content {
  overflow-y: scroll;
  text-align: center;
}

.arrow-top {
  padding: 10px;
  border: solid 1px#afb2b3;
  border-radius: 40px;
  margin-bottom: -60px;
  margin-top: 20px;
  cursor: pointer;
}

.arrow-top:hover {
  background-color: white;
  border: solid 1px #1d3c45;
}

.new-review-form {
  width: 90%;
  height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.review-textarea {
  color: black;
  font-family: "Poppins";
  font-size: 18px;
  border-radius: 5px;
  line-height: 22px;
  background-color: transparent;
  border: 1px solid #1d3c45;
  transition: all 0.3s;
  padding: 13px;
  margin-bottom: -110px;
  width: 100%;
  box-sizing: border-box;
  outline: 0;
}

.review-textarea:focus {
  border: 2px solid #d2601a;
}
.review-textarea::placeholder {
  font-weight: 400;
  color: #747575;
}

.new-review-send-btn {
  font-family: "Poppins";
  width: 50%;
  background: #1d3c45;
  border-radius: 5px;
  border: 0;
  cursor: pointer;
  color: white;
  font-size: 24px;
  padding-top: 10px;
  padding-bottom: 10px;
  transition: all 0.3s;
  margin-top: 30px;
  margin-bottom: 30px;
}

.new-review-send-btn:hover {
  background-color: #d2601a;
  letter-spacing: 0.6px;
}

.spinner {
  animation: spin infinite 1.5s linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.warning-delete-review {
  width: 50vw;
  border: solid 1px #1d3c45;
  border-radius: 5px;
  position: fixed;
  left: 25%;
  right: 25%;
  top: 50%;
  background-color: #e7ebed;
  z-index: 150;
  padding: 10px;
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: 0.5s ease-in-out;
}

.first-container-warning-delete-review {
  text-align: center;
  font-family: "Poppins";
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  height: 100%;
}

.btn-container-warning-delete-review {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.delete-btn {
  font-family: "Poppins";
  font-weight: 400;
  padding: 10px;
  border: solid 1px #e22810;
  border-radius: 5px;
  font-size: 0.92rem;
  cursor: pointer;
  margin-right: 10px;
}

.delete-btn:hover {
  color: white;
  background-color: #e22810;
}

.cancel-btn {
  font-family: "Poppins";
  font-weight: 400;
  padding: 10px;
  border: solid 1px #747575;
  border-radius: 5px;
  font-size: 0.92rem;
  cursor: pointer;
  margin-left: 10px;
}

.cancel-btn:hover {
  background-color: #747575;
  color: white;
}

.trashlogo {
  cursor: pointer;
}

.greying {
  width: 100%;
  height: 100%;
  transition: 0.2s ease-in-out;
}
