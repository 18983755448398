.gallery-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.cards-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 50px;
  padding: 20px;
}

.gallery-card {
  padding: 20px;
  background-color: #1d3c45;
  color: white;
  font-family: "Poppins";
  width: 30vw;
  height: 30vh;
  border: solid 1px #1d3c45;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: 1s ease-in-out;
  opacity: 0.9;
}

.gallery-card:hover {
  transition: 1.1s ease-in-out;
  letter-spacing: 5px;
  opacity: 1;
}

#gallery-card2 {
  margin-left: 20px;
}

.gallery-card-title {
  margin-bottom: 0;
}

.gallery-card-logo {
  margin-bottom: -25px;
  transition: 1s ease-in-out;
}

.gallery-card-logo-animation {
  transition: 1s ease-in-out;
  transform: rotateY(360deg);
}

.gallery-card-logo-animation2 {
  transition: 1s ease-in-out;
  transform: rotateY(360deg);
}
