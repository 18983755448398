.about-top {
  width: 100vw;
  height: 20vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #1d3c45;
  box-sizing: border-box;
}

.about-title-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  box-sizing: border-box;
}

.about-title {
  font-family: "Caveat";
  color: #f5f5f5;
  font-size: 4rem;
  box-sizing: border-box;
}

.about-container {
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f5f5f5;
  box-sizing: border-box;
  overflow-x: hidden;
}

.about-text {
  font-family: "Poppins";
  width: 80vw;
  color: #1d3c45;
  text-align: justify;
  box-sizing: border-box;
}

.carousel-container {
  width: 50%;
  position: relative;
  margin-bottom: 30px;
  box-sizing: border-box;
}

.about-card {
  font-family: "Poppins";
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25rem;
  max-width: 80%;
  border: solid 1px #f5f5f5;
  border-radius: 5px;
  background-color: #1d3c45;
  margin-top: 30px;
  margin-bottom: 30px;
  box-sizing: border-box;
}

.about-card-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  box-sizing: border-box;
}

.about-card-title {
  color: #f5f5f5;
  box-sizing: border-box;
}

.about-card-text {
  color: #f5f5f5;
  text-align: center;
  box-sizing: border-box;
}

.about-img {
  width: 45%;
  height: 100%;
  box-sizing: border-box;
}

.repetitions-card {
  background-color: rgba(222, 223, 222, 0.8);
  width: 80vw;
  padding: 10px;
  border: solid 1px #1d3c45;
  border-radius: 5px;
  margin-top: 30px;
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

@media only screen and (max-height: 500px) {
  .about-top {
    height: 35vh;
  }
}

@media only screen and (max-width: 500px) {
  .about-title {
    font-size: 3rem;
  }
}

@media only screen and (max-width: 800px) {
  .about-card-text {
    font-size: 1em;
  }
  .carousel-container {
    width: 100%;
  }
}

@media only screen and (max-width: 600px) {
  .about-card-text {
    font-size: 0.8em;
  }
  .about-card-title {
    font-size: 1.4em;
  }
}

@media only screen and (max-width: 400px) {
  .about-card-text {
    font-size: 0.7em;
  }
  .about-card-title {
    font-size: 1.2em;
  }
}
