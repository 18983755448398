.events-container {
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.events-top {
  width: 100vw;
  height: 20vh; /* <--- mettre env. 30vh en max-height: env. 500px */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #1d3c45;
}

.events-title-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}

.events-title {
  font-family: "Caveat";
  color: #f5f5f5;
  font-size: 4rem;
}

.calendar-logo {
  width: 90px;
  height: 90px;
  padding-left: 1em;
}

.filter-btn-container {
  width: 80%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 2em;
  box-sizing: border-box;
}

.filter-btn {
  padding: 10px;
  font-family: "Poppins";
  font-size: 1.3rem;
  font-weight: 400;
  border-radius: 10px;
  border: solid 1px #1d3c45;
  background-color: #d6d1d1;
  color: #1d3c45;
  cursor: pointer;
}

.filter-btn::after {
  color: whitesmoke;
  background-color: #1d3c45;
}

.filter-btn.active {
  padding: 10px;
  font-family: "Poppins";
  font-size: 1.3rem;
  font-weight: 400;
  border-radius: 10px;
  border: solid 1px #1d3c45;
  color: whitesmoke;
  background-color: #1d3c45;
  cursor: pointer;
}

.create-event-btn {
  margin-top: 20px;
  padding: 10px;
  font-family: "Poppins";
  font-size: 1.3rem;
  font-weight: 400;
  border: solid 1px #2ecc71;
  border-radius: 10px;
  background-color: #e7ebed;
  color: #2ecc71;
  cursor: pointer;
}

.create-event-btn:hover {
  background-color: #2ecc71;
  color: whitesmoke;
}

.events-line {
  width: 60%;
  border-bottom: solid 1px #1d3c45;
  margin-top: 20px;
}

.past-events-title {
  font-family: "Poppins";
  color: rgba(60, 59, 60, 0.652);
}

/* --------------- CARD --------------- */

.image-event-card {
  height: 100%;
}

figure {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30vw;
  height: 20em;
  border-radius: 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  overflow: hidden;
  cursor: pointer;
  box-sizing: border-box;
}

figure:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

figure:hover img {
  transform: scale(1.15);
}

.event-title {
  font-family: "Poppins";
  margin-top: -28px;
  color: white;
}

.event-image2 {
  height: 100%;
  transition: 0.25s;
}

figcaption {
  position: absolute;
  bottom: -70%;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 30px;
  background-color: rgba(black, 0.85);
  box-shadow: 0 0 20px rgba(black, 0.4);
  color: white;
  line-height: 1;
  transition: 0.25s;
  box-sizing: border-box;
  text-align: center;
}

.body-title {
  margin: 0 0 20px;
  padding: 0;
  color: whitesmoke;
}

.event-text {
  font-family: "Poppins";
  margin-top: -10px;
}

.card-event-end {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.card-event-btn {
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  margin: 10px 10px 10px;
  padding: 10px;
  border: solid 1px #1d3c45;
  border-radius: 5px;
  color: whitesmoke;
  font-size: 1em;
}

.card-event-btn:hover {
  background-color: #1d3c45;
  border-color: whitesmoke;
}

@media only screen and (max-width: 800px) {
  .event-title {
    font-size: medium;
  }
  .event-text {
    font-size: small;
    margin-top: 6px;
  }
  .card-event-btn {
    margin-top: 0;
  }
  figure {
    width: 40vw;
  }
  .my-masonry-grid {
    display: block !important;
  }
}

@media only screen and (min-width: 1400px) {
  .my-masonry-grid > :nth-child(1),
  .my-masonry-grid > :nth-child(2) {
    width: 50% !important;
  }
  figure {
    width: 20vw!important;
  }
}

@media only screen and (min-width: 1100px) {
  figure {
    width: 35vw;
  }
  .my-masonry-grid {
    margin-left: 30px !important;
  }
}

@media only screen and (max-width: 450px) {
  .event-title {
    font-size: small;
  }
  .event-text {
    font-size: small !important;
    margin-top: 6px;
  }
}

@media only screen and (max-height: 500px) {
  .events-top {
    height: 35vh;
  }
}
