.videos-container {
  text-align: center;
}

.videos-dl-title {
  font-family: "Poppins";
}

.videos-grid {
  max-width: 100vw;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-gap: 16px;
  margin-top: 30px;
}

.video-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.video {
  width: 100%;
  height: 100%;
}

.video-title-container {
  width: 100%;
  background-color: #000000cb;
  height: 3vh;
}

.video-title {
  font-family: "Poppins";
  margin-top: 0;
  color: white;
}

@media only screen and (max-width: 1200px) {
  .videos-grid {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(3, 1fr);
  }
}

@media only screen and (max-width: 900px) {
  .videos-grid {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(4, 1fr);
  }
}

@media only screen and (max-width: 700px) {
  .videos-grid {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(5, 1fr);
  }
}

@media only screen and (max-width: 440px) {
  .videos-grid {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(10, 1fr);
  }
}

.video-popup {
  width: 99vw;
  position: fixed;
  top: 0;
  z-index: 203;
}

.video-zoom {
  width: 100%;
}

.video-popup-top {
  background-color: #000000cb;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 5vh;
}

@media only screen and (max-height: 450px) {
  .video-popup {
    width: 80vw;
    left: 10%;
  }
}

@media only screen and (max-width: 500px) {
  .video-popup {
    top: 25vh;
  }
}
