.card {
  display: flex;
  justify-content: center;
  align-items: center;
}

.box {
  font-family: "Poppins";
  padding: 50px;
  background: #1d3c45;
  text-align: center;
  transition: 0.25s;
  margin-top: 10vh;
  border-radius: 5px;
  margin-bottom: 40px;
}

.log-title {
  color: whitesmoke;
  margin-bottom: 30px;
  font-weight: 700;
}

.info-registration {
  margin-bottom: 30px;
  font-size: large;
}

.box input[type="text"],
.box input[type="password"] {
  font-size: large;
  border: 0;
  background: none;
  display: block;
  margin: 20px auto;
  text-align: center;
  border: 2px solid white;
  padding: 10px 10px;
  width: 80%;
  outline: none;
  color: white;
  border-radius: 24px;
  transition: 0.25s;
}

.box input[type="text"]:hover,
.box input[type="password"]:hover {
  width: 80%;
  border-color: #d2601a;
}

.box input[type="text"]:focus,
.box input[type="password"]:focus {
  width: 70%;
  border-color: #d2601a;
}

.form-questions {
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.forgot {
  color: whitesmoke;
}

.box input[type="submit"] {
  width: 80%;
  border: 0;
  background: none;
  display: block;
  margin: 20px auto;
  text-align: center;
  border: 2px solid #d2601a;
  padding: 14px 40px;
  outline: none;
  color: white;
  border-radius: 20px;
  transition: 0.25s;
  cursor: pointer;
}

.box input[type="submit"]:hover {
  background: #d2601a;
}

@media only screen and (max-width: 899px) {
  .box input[type="submit"] {
    width: 90%;
  }
  .box input[type="text"],
  .box input[type="password"] {
    width: 90%;
  }
  .box input[type="text"]:hover,
  .box input[type="password"]:hover {
    width: 95%;
    border-color: #d2601a;
  }

  .box input[type="text"]:focus,
  .box input[type="password"]:focus {
    width: 95%;
    border-color: #d2601a;
  }
}

@media only screen and (min-width: 900px) {
  .box input[type="submit"] {
    width: 50%;
  }
  .box input[type="text"],
  .box input[type="password"] {
    width: 70%;
  }
}

@media only screen and (min-width: 1500px) {
  .box input[type="submit"] {
    width: 50%;
  }
  .box input[type="text"],
  .box input[type="password"] {
    width: 50%;
  }
}

@media only screen and (min-width: 2000px) {
  .box input[type="submit"] {
    width: 50%;
  }
  .box input[type="text"],
  .box input[type="password"] {
    width: 40%;
  }
}
