@import url("https://fonts.googleapis.com/css2?family=Caveat");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600&display=swap");

body {
  height: 100vh;
  width: 100vw;
  background-color: whitesmoke;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  transition: all 0.8s ease-in-out;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4 {
  font-weight: 400;
}

.assoc-name {
  font-size: 4.5rem;
}
