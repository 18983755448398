.homepage-section1 {
  width: 100%;
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  font-family: "Poppins";
  transition: all 0.8s ease-in-out;
}

.home-picture {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.video-content {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.video-card {
  position: absolute;
  top: -90%;
  background-color: rgba(0, 0, 0, 0.2);
  text-align: center;
  padding: 2.5rem;
  border-radius: 5px;
}

.title1-video {
  font-size: 1.9rem;
  font-weight: 600;
  color: whitesmoke;
  text-align: justify;
}

.title2-video {
  font-size: 1.4rem;
  margin-top: -20px;
  color: #1d3c45;
  text-align: justify;
}

.video-card-text {
  text-align: justify;
  color: whitesmoke;
  margin-bottom: 40px;
  font-size: 1.2rem;
}

.home-btn {
  background-color: #1d3c45;
  border: solid 2px #1d3c45;
  color: whitesmoke;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  font-size: 1.3rem;
  transition: 0.2s ease-in-out;
}

.home-btn:hover {
  background-color: whitesmoke;
  color: #1d3c45;
}

@media only screen and (max-height: 700px) {
  .title1-video {
    font-size: 1.2rem;
    font-weight: 600;
  }

  .title2-video {
    font-size: 1rem;
    margin-top: -20px;
  }

  .video-card-text {
    margin-bottom: 40px;
    font-size: 0.95rem;
  }

  .home-btn {
    font-size: 1.2rem;
  }
}

@media only screen and (max-width: 900px) and (max-height: 420px) {
  .video-card {
    padding: 1rem;
  }

  .title1-video {
    font-size: 1.2rem;
    font-weight: 600;
  }

  .title2-video {
    font-size: 1rem;
    margin-top: -20px;
  }

  .video-card-text {
    margin-bottom: 40px;
    font-size: 0.95rem;
  }

  .home-btn {
    font-size: 1.2rem;
  }
}

.citation-container {
  padding: 20px;
  margin-top: 40px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  font-family: "Poppins";
}

.citation {
  font-size: 1.3rem;
}

.citation-author {
  margin-top: 0;
  font-family: "Caveat";
  font-size: 1.7rem;
}

.homepage-section2 {
  margin-top: 40px;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Poppins";
  background-color: #1d3c45;
  max-height: 80vh;
  transition: 0.8s ease-in-out;
}

.homepage-section2-right {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: justify;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.homepage-section2-right-title {
  color: whitesmoke;
  font-size: 1.8rem;
}

.homepage-section2-right-text {
  color: whitesmoke;
}

.home-btn-joinus {
  margin-top: 20px;
  margin-bottom: -20px;
  background-color: whitesmoke;
  border: solid 2px #1d3c45;
  color: #1d3c45;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  font-size: 1.3rem;
  transition: 0.2s ease-in-out;
}

.home-btn-joinus:hover {
  background-color: #1d3c45;
  border-color: whitesmoke;
  color: whitesmoke;
}

.homepage-section2-left {
  padding: 30px;
  width: 100%;
  box-sizing: border-box;
  transition: 0.8s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
}

.img1-home {
  width: 80%;
  height: 100%;
  box-shadow: 8px 8px 10px 0 rgba(0, 0, 0, 0.5);
  transition: 0.2s ease-in-out;
  margin-bottom: 40px;
}

.img1-home:hover {
  transition: 0.8s ease-in-out;
  transform: scale(1.1);
}

@media only screen and (max-width: 950px) {
  .homepage-section2 {
    flex-direction: column;
    max-height: 100vh;
  }
  .homepage-section2-right {
    height: 100%;
    margin-top: -40px;
  }
}

@media only screen and (max-height: 500px) {
  .homepage-section2 {
    flex-direction: row;
    max-height: 100vh;
  }
  .homepage-section2-right {
    height: 100%;
    margin-top: -40px;
  }
  .homepage-section1 {
    height: 120vh;
  }
  .video-card {
    top: -120%;
    padding: 2rem;
  }
}
