.email-sent-container {
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.confirm-logo {
  margin-top: 30px;
}

.email-sent-title {
  font-family: "Poppins";
}

.email-sent-text {
  font-family: "Poppins";
}
